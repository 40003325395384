export const vendorCopy = `At Flavour Culture we believe flavour comes first. We achieve this by using fresh & high quality ingredients, preparing them using various cooking styles & seasonings to create our dishes to the very best standard. We present this to you in the form of street food from around the world.`

export const vendorInfo = [
  {
    name: "BRATHAUS",
    subheading: "Authentic German Sausage.",
    text: [
      `Our BRATHAUS concept brings authentic German sausages to London all year round. We choose only the finest quality bratwurst up to a length of 30cm (12”) & grill using premium lumpwood charcoal for the best possible flavour.`,
      ,
      `There is quality at every step, from a choice of toppings including German “Bautzner” mustard right down to the freshly baked baguette we serve your wurst in, with the ends sticking out of course! We have a varied menu of German sausages to choose from.`,
      ,
      `German street food classics are some of our most popular dishes with our famous currywurst & special curry salted fries. Now one of the only vendors in London offering a vegan currywurst in addition to our veganwurst, a regular favourite for the best experience without the meat.`,
      ,
    ],
    insta: {
      name: `@brathausldn`,
      url: `https://www.instagram.com/brathausldn/`,
    },
  },
  {
    name: "Naan Stop",
    subheading: "Fresh & vibrant naan wraps. As spicy as you like it.",
    text: [
      `We love Indian food but often it can be very oily. At Naan Stop we want you to experience a healthy and nutritious meal which provides that comfort food feeling without the guilt.`,
      `START from scratch with our freshly handmade naan.`,
      `NEXT choose from a juicy selection of marinated meats or seasonal vegetables, this can be made as spicy as you like it, how much of our secret masala can you handle?`,
      `THEN load it with refreshing salads, punchy pickles & sauces that we create to complement the flavours. `,
      `FINALLY, choose from our Kenyan masala chips (the sauce that always gets people talking), Samosas and special Indian street snacks.`,
    ],
  },
  {
    name: "Flavour Culture Catering",
    subheading:
      "Got an event with hungry mouths to feed? We’ve got you covered.",
    text: [
      `At Flavour Culture we believe if there’s one kind of story we all share, it’s one about food. It brings people together, from family meals at home, fresh food trucks at a music festival to a quick lunch break on a film set. It’s that feeling of something to look forward to & that connection of people around food that we bring to your clients.`,
    ],
    insta: {
      name: `@flavour_culture`,
      url: `https://www.instagram.com/flavour_culture/`,
    },
  },
]
